import styled from "styled-components";
import ContentSection from "components/front/layout/ContentSection";

const DocumentSection = styled(ContentSection)`
  font-family: "Yu Gothic", "YuGothic", "Roboto", sans-serif;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.6;

  & > section {
    margin-top: 32px;
    margin-bottom: 48px;

    h1 {
      font-size: 34px;
      font-weight: 400;
      text-align: center;
      padding: 48px;
    }

    h4 {
      margin-bottom: 16px;
      font-size: 18px;
      font-weight: bold;
    }

    p {
      margin-bottom: 16px;

      strong {
        font-weight: bold;
        margin-right: 10px;
      }
    }

    ul {
      margin-bottom: 16px;
    }

    table {
      &.temporary {
        margin-top: 10px;
      }
      & > tbody {
        & > tr {
          & > th {
            padding: 16px;
            text-align: left;
            font-weight: bold;
            background: #e5e5e5;
            border: solid 1px #b5b5b5;
            @media screen and (max-width: 960px) {
              padding: 8px;
            }
          }

          & > td {
            padding: 16px;
            border: solid 1px #b5b5b5;
            @media screen and (max-width: 960px) {
              padding: 8px;
            }

            & > p {
              margin-bottom: 8px;

              &:last-child {
                margin-bottom: 0;
              }
            }

            & > a {
              color: #0000ff;
            }
          }
        }
      }
    }
  }
`;
export default DocumentSection;
