import { goErrorPage, goLoginPage } from "lib/frontRouting";
import * as Sentry from "@sentry/browser";
import { useCallback } from "react";
import { ErrorV2, Failure } from "lib/service/BaseClientService";
import { NextRouter } from "next/router";

const useErrorHandler = () => {
  return useCallback(
    async (
      result: Failure<unknown, ErrorV2>,
      errorMessage: string,
      router: NextRouter,
      customErrorHandling: (message: string) => Promise<void>
    ) => {
      if (result.failureType === "unauthorized") {
        await goLoginPage(router);
      } else if (!result.value.message) {
        Sentry.captureMessage(errorMessage, { extra: { error: result.value } });
        await goErrorPage(router);
      } else {
        await customErrorHandling(result.value.message);
      }
    },
    []
  );
};
export default useErrorHandler;
